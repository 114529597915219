(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('ErrorReportController', ErrorReportController);

    ErrorReportController.$inject = ['$scope', '$http', '$state'];

    function ErrorReportController ($scope, $http, $state) {
        var vm = this;

        loadData();

        function loadData() {
            $http.get('/api/error/report').then(function(result) {
                vm.errorReport = result.data;
            });
        }

        $scope.showConfirmDialog = function(errorReport) {
            $scope.errorReportToDelete = errorReport;
            $('#confirmDelete').modal('show');
        };

        $scope.deleteErrorReport = function () {
            var errorReport = $scope.errorReportToDelete;
            $http.delete('/api/error/report/' + errorReport.id).then(function(result) {
                loadData();
                $('#confirmDelete').modal('hide');
            });
        }

    }
})();
