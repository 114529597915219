(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('TokenController', TokenController);

    TokenController.$inject = ['$scope', '$http', '$state'];

    function TokenController ($scope, $http, $state) {
        var vm = this;

        loadData();

        function loadData() {
            $http.get('/api/all/tokens').then(function(result) {
                vm.allTokens = result.data;
            });
        }

    }
})();
