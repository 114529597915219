(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('TeoriproveResultController', TeoriproveResultController);

    TeoriproveResultController.$inject = ['$scope', '$http', '$state', '$stateParams', '$timeout'];

    function TeoriproveResultController ($scope, $http, $state, $stateParams, $timeout) {
        var vm = this;

        vm.hasTokenExpired = true;

        loadResult();

        function getToken() {
            return localStorage.getItem('sessionToken');
        }

        function loadResult() {
            var sessionId = $stateParams.sessionId;
            $http.get('api/' + getToken() + '/practice/' + sessionId + '/result')
                .then(function(result) {
                    vm.hasTokenExpired = result.data.hasTokenExpired;
                    $scope.result = result.data;
                }, function () {
                    alert("En feil oppstod.");
                });
        }

        $scope.showStartTemaproveExerciseModal = function (subject) {
            $scope.startTemaproveExerciseData = {};
            $scope.startTemaproveExerciseData.subject = subject;
            $('#startTemaproveExercise').modal('show');
        };

        $scope.startTemaproveExercise = function () {
            $('#startTemaproveExercise').modal('hide');

            // Wait a little bit to make the modal window close properly first
            $timeout(function() {
                $state.go('teoriprove', {
                    "type": $scope.startTemaproveExerciseData.practiceType,
                    "subject": $scope.startTemaproveExerciseData.subject
                });
            }, 300);
        };
    }
})();
