(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('ExerciseDetailController', ExerciseDetailController);

    ExerciseDetailController.$inject = ['$scope', '$http','Principal', 'LoginService', '$state', '$stateParams', '$location'];

    function ExerciseDetailController ($scope, $http, Principal, LoginService, $state, $stateParams, $location) {
        var vm = this;

        vm.exercise = {};

        vm.previewImage = false;
        vm.currentId = $stateParams.id;

        // Available exercise types.
        vm.types = [
            {
                value: 'REGULAR',
                name: 'Teori/tema'
            }, {
                value: 'ROAD_SIGN',
                name: 'Skiltprøve'
            }
        ];

        // All available license classes.
        vm.licenseClasses = [
            {
                value: 'ALL',
                name: 'Alle'
            }, {
                value: 'LICENSE_CLASS_A',
                name: 'Førerkort klasse A'
            }, {
                value: 'LICENSE_CLASS_B',
                name: 'Førerkort klasse B'
            }
        ];

        loadData();

        function loadData() {
            if ($stateParams.id !== 'new') {
                $http.get('/api/exercise/' + $stateParams.id).then(function(result) {
                    vm.exercise = result.data;
                });
            }

            $http.get('/api/subjects').then(function(result) {
                vm.subjects = result.data;
            });
        }

        vm.addOption = function() {
            if(!vm.exercise.options) {
                vm.exercise.options = [];
            }
            vm.exercise.options.push({text: '', description: '', correct: false});
        };

        vm.removeOption = function(option) {
            vm.exercise.options.splice(vm.exercise.options.indexOf(option), 1);
        };

        vm.removeImage = function() {
            if ($stateParams.id !== 'new') {
                $http.delete('/api/exercise/' + $stateParams.id + '/delete/image/').then(function() {
                    vm.exercise.imageUrl = null;
                });
            }
            angular.element("#file").val('');
            vm.previewImage = false;
        };

        vm.save = function() {
            $http.post('/api/exercise', vm.exercise).then(function(result) {
                if (document.getElementById('file').files[0] !== null) {
                    var formData = new FormData();
                    formData.append('file', document.getElementById('file').files[0]);
                    formData.append('id', result.data);
                    $http.post("/api/exercise/upload/image", formData, {
                        headers: {
                            'Content-Type': undefined
                        }
                    }).then(function() {});
                }
            });
        };

        vm.next = function() {
            var nextId = parseInt(vm.currentId) + 1;
            $location.url("admin/exercises/" + nextId);
        };

        vm.prev = function() {
            var prevId = parseInt(vm.currentId) - 1;
            $location.url("admin/exercises/" + prevId);
        };

        angular.element("#file").change(function(){
            vm.previewImage = true;
            $scope.$apply();
            // eslint-disable-next-line angular/controller-as-vm
            readURL(this);
            vm.save();
        });

        function readURL(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    angular.element('#image').attr('src', e.target.result);
                };

                reader.readAsDataURL(input.files[0]);
            }
        }

    }
})();
