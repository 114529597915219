(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('PracticeController', PracticeController);

    PracticeController.$inject = ['$scope', '$http', '$state'];

    function PracticeController ($scope, $http, $state) {
        var vm = this;

        $scope.showExplanation = false;

        loadData();

        function loadData() {
            $http.get('/api/exercises/random/1').then(function(result) {
                $scope.exercise = result.data[0];
            });
        }

        /*
        $scope.exercise = {
            subject: {
                subject: 'Fører og eiers ansvar'
            },
            text: 'Hva er riktig når tilhengeren ikke har bremser og ikke er lastet?',
            imageUrl: '/images/1.png',
            options: [
                {
                    id: 1,
                    no: 'A',
                    text: 'Tilhengere uten bremser kan ikke brukes i Norge.',
                    color: 'white',
                    correct: false // TODO: remove
                },
                {
                    id: 2,
                    no: 'B',
                    text: 'Bilføreren må ha førerkort klasse B kode 96 eller førerkort klasse BE.',
                    color: 'white',
                    correct: false // TODO: remove
                },
                {
                    id: 3,
                    no: 'C',
                    text: 'Når det trekkes tilhengere uten bremser er høyeste tillatte fart 60 km/t.',
                    color: 'white',
                    correct: false // TODO: remove
                },
                {
                    id: 4,
                    no: 'D',
                    text: 'Når det trekkes tilhenger er høyeste tillatte fart 80 km/t.',
                    color: 'white',
                    correct: true // TODO: remove
                }
            ],
            explanation: 'Trafikkreglene § 13. Særlige bestemmelser om kjørefarten: På vegstrekninger med særskilt fartsgrense høyere enn 80 km i timen må motorvogn med tillatt totalvekt over 3500 kg eller motorvogn med tilhenger ikke kjøres fortere enn 80 km i timen'
        }
        */

        $scope.answer = function(option) {
            if (option.correct === true) {
                option.color = '#5cb85c';
            } else {
                option.color = '#d9534f';
                // TOOD: Need to find correct answer from server
                var i;
                for (i = 0; i < $scope.exercise.options.length; i++) {
                    var exerciseOption = $scope.exercise.options[i];
                    if (exerciseOption.correct === true) {
                        exerciseOption.color = '#5cb85c';
                        break;
                    }
                }
            }
            $scope.showExplanation = true;
        };

        $scope.reload = function() {
            $state.reload();
        }

    }
})();
