(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('TeoriproveController', TeoriproveController);

    TeoriproveController.$inject = ['$scope', '$http', '$state', '$stateParams', '$window', '$location', '$anchorScroll', '$timeout'];

    function TeoriproveController ($scope, $http, $state, $stateParams, $window, $location, $anchorScroll, $timeout) {
        var vm = this;

        $scope.errorReport = {};

        $scope.practiceType = $stateParams.type;

        if ($stateParams.type === 'TEMAPROVE_10' || $stateParams.type === 'SKILTPROVE_10') {
            vm.numberOfExercises = 10;
        } else if ($stateParams.type === 'TEMAPROVE_20') {
            vm.numberOfExercises = 20;
        } else if ($stateParams.type === 'TEMAPROVE_30') {
            vm.numberOfExercises = 30;
        } else {
            vm.numberOfExercises = 45;
        }

        loadFirstExercise();

        function getToken() {
            return localStorage.getItem('sessionToken');
        }

        function loadFirstExercise() {
            var data = {
                type: $stateParams.type,
                subject: $stateParams.subject
            };
            $http.post('api/' + getToken() + '/practice/new', data).then(function(result) {
                if (result.data.id === null) {
                    alert("Du har dessverre ikke tilgang til å starte ny prøve. Ønsker du å starte ny prøve kan du " +
                        "kjøpe tilgang ved å trykke på 'Kjøp tilgang nå'.");
                    $state.go('session');
                } else {
                    $scope.exercise = result.data;
                }
            });
        };

        $scope.next = function() {
            $scope.showExplanation = false;
            $http.get('api/' + getToken() + '/practice/' + $stateParams.type + '/' + $scope.exercise.sortOrder + '/next').then(function(result) {
                $scope.exercise = result.data;
            });
        };

        $scope.answer = function(option) {
            var data = {
                exerciseId: $scope.exercise.id,
                optionId: option.id
            };
            $http.post('api/' + getToken() + '/practice/' + $stateParams.type + '/answer', data).then(function(result) {
                $scope.exercise.explanation = result.data.explanation;
                if (result.data.correct === true) {
                    option.color = '#5cb85c';
                } else {
                    option.color = '#d9534f';
                    angular.forEach($scope.exercise.options, function(value, key) {
                        if (value.id === result.data.correctId) {
                            value.color = '#5cb85c';
                        }
                    });
                }
                $scope.showExplanation = true;
                $scope.practiceSessionDone = result.data.practiceSessionDone;

                // Scroll to bottom of page.
                $scope.scrollToBottom();
            });
        };

        $scope.scrollToBottom = function() {
            // Call $anchorScroll with the ID of the anchor at the bottom
            $timeout(function() {
                $anchorScroll('bottomAnchor');
            });
        };

        $scope.showReportErrorModal = function(exercise) {
            $scope.errorReport.exercise = exercise;
            $scope.errorReport.errorType = '';
            $('#reportErrorModal').modal('show');
        };

        $scope.sendErrorReport = function() {
            $http({
                method: 'POST',
                url: '/api/error/report',
                data: {
                    exerciseId: $scope.errorReport.exercise.id,
                    errorType: $scope.errorReport.errorType,
                    errorMessage: $scope.errorReport.errorMessage
                }
            }).then(function(response) {
                $('#reportErrorModal').modal('hide');
            }, function(response) {
                $('#reportErrorModal').modal('hide');
                console.log('An error occurred when uploading project log');
                console.log(result);
            });
        }

    }
})();
