(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('session', {
            parent: 'app',
            url: '/session/:token',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/session/session.html',
                    controller: 'SessionController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('info-to-students', {
            parent: 'app',
            url: '/informasjon',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/session/info-to-students.html'
                }
            }
        });
    }
})();
