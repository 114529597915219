(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$http', 'AlertService', '$location'];

    function HomeController ($scope, Principal, LoginService, $http, AlertService, $location) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;

        vm.selectedNumberOfDays = "20";

        // Variables for chart.
        vm.labels = [];
        vm.series = ['Antall prøver'];
        vm.data = [];
        vm.myColors = ['#00ADF9'];

        // Put origin in local storage - if provided.
        if ($location.search().origin) {
            localStorage.setItem('origin', $location.search().origin)
        }

        getAccount();

        function loadData() {
            $http.get('/api/tokens').then(function(result) {
                vm.tokens = result.data;
            });

            // Only load chart data for admin.
            if (vm.account != null && Principal.hasAuthority('ROLE_ADMIN')) {
                $scope.loadChartData();
            }
        }

        $scope.loadChartData = function() {
            $http.get('/api/statistics/numberOfSessionsPerDay/' + vm.selectedNumberOfDays)
                .then(function (result) {
                    vm.labels = result.data.labels;
                    vm.data = [result.data.numbers];
                });
        };

        $scope.buyWithVipps = function() {
            alert('hello!');
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
            loadData(); // Force a reload on authentication.
        });

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                // Load data if we have an account (i.e., is authenticated).
                if (vm.account) {
                    loadData();
                }
            });
        }

        $scope.showConfirmPurchaseModal = function(numKeys, pricePerKey) {
            vm.purchase = {};
            vm.purchase.numKeys = numKeys;
            vm.purchase.pricePerKey = pricePerKey;
            $('#confirmPurchaseModal').modal('show');
        };

        $scope.showConfirmDistributionModal = function(token) {
            vm.distribute = {};
            vm.distribute.token = token.token;
            vm.distribute.email = token.sentToEmail;
            $('#sendKeyToStudentModal').modal('show');
        };

        $scope.showConfirmRenewalOfTokenModal = function(token) {
            // TODO: Check if there are any available tokens.
            vm.renewal = {};
            vm.renewal.token = token.token;
            vm.renewal.student = token.student;
            $('#confirmRenewalOfTokenModal').modal('show');
        };

        $scope.showPrivacyPolicyModal = function() {
            $('#privacyPolicyModal').modal('show');
        };

        $scope.executePurchase = function() {
            $http({
                method: 'POST',
                url: 'api/tokens/create/' + vm.purchase.numKeys
            }).then(function(response) {
                AlertService.success("Kjøp gjennomført.", null, 'bottom right');
                loadData();
            }, function(response) {
                AlertService.error("En feil oppstod. Sjekk loggen!", null, 'bottom right');
                console.log(response);
            });
            $('#confirmPurchaseModal').modal('hide');
        };

        $scope.executeDistribution = function() {
            $http({
                method: 'POST',
                url: 'api/distribute/' + vm.distribute.token,
                data: {
                    email: vm.distribute.email
                }
            }).then(function(response) {
                AlertService.success("E-post sendt til " + vm.distribute.email + ".", null, 'bottom right');
                loadData();
            }, function(response) {
                AlertService.error("En feil oppstod. Sjekk loggen!", null, 'bottom right');
                console.log(response);
            });
            $('#sendKeyToStudentModal').modal('hide');
        };

        $scope.executeRenewal = function() {
            $http({
                method: 'POST',
                url: 'api/renew/' + vm.renewal.token
            }).then(function(response) {
                AlertService.success("Nøkkelen er fornyet.", null, 'bottom right');
                loadData();
            }, function(response) {
                AlertService.error("En feil oppstod. Sjekk loggen!", null, 'bottom right');
                console.log(response);
            });
            $('#confirmRenewalOfTokenModal').modal('hide');
        };

    }
})();
