(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('ExerciseController', ExerciseController);

    ExerciseController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$http'];

    function ExerciseController ($scope, Principal, LoginService, $state, $http) {
        var vm = this;

        $scope.sortType = 'id';
        $scope.sortReverse = false;
        $scope.searchTable = '';

        loadData();

        $scope.deleteExercise = function() {
            var exercise = $scope.deleteExerciseCandidate;
            $http.delete('/api/exercise/' + exercise.id).then(function(result) {
                loadData();
                $('#confirmDelete').modal('hide');
            });
        }

        function loadData() {
            $http.get('/api/exercise/all').then(function(result) {
                $scope.exercises = result.data;
            });
        }

        $scope.showConfirmDialog = function(exercise) {
          $scope.deleteExerciseCandidate = exercise;
          $('#confirmDelete').modal('show');
        }

    }
})();
