(function() {
    'use strict';

    angular
        .module('bestaaApp')
        .controller('SessionController', SessionController);

    SessionController.$inject = ['$scope', '$state', '$http', '$stateParams', '$timeout'];

    function SessionController ($scope, $state, $http, $stateParams, $timeout) {
        var vm = this;

        vm.hasValidSessionToken = false;
        vm.hasTokenExpired = false;
        vm.hasInProgressTeoriprove = false;
        vm.hasInProgressTemaprove = false;
        vm.practiceType = null;
        vm.subject = null;
        vm.register = register;
        vm.registerDetails = {};

        validateToken($stateParams.token);

        function register () {
            // Get token from local storage
            var token = localStorage.getItem('sessionToken');
            $http.post('api/updateToken/' + token, vm.registerDetails).then(function(result) {
                vm.isRegistered = true;
            });
        }

        function validateToken(token) {
            if (!token) {
                token = localStorage.getItem('sessionToken');
            }
            $http.get('/api/loginWithToken/' + token).then(function(result) {
                vm.hasTokenExpired = result.data.hasTokenExpired;
                vm.expiryDate = result.data.expiryDate;
                vm.numberOfDaysLeftUntilExpiry = result.data.numberOfDaysLeftUntilExpiry;
                vm.teoriproveSessions = result.data.teoriproveSessions;
                vm.temaproveSessions = result.data.temaproveSessions;
                vm.skiltproveSessions = result.data.skiltproveSessions;
                vm.hasInProgressTeoriprove = result.data.hasInProgressTeoriprove;
                vm.hasInProgressTemaprove = result.data.hasInProgressTemaprove;
                vm.hasInProgressSkiltprove = result.data.hasInProgressSkiltprove;
                vm.practiceType = result.data.practiceType;
                vm.subject = result.data.subject;
                vm.hasValidSessionToken = result.data.validToken;
                vm.isRegistered = result.data.registered;
                vm.registerDetails.email = result.data.sentToEmailAddress;

                // If session token is valid, store this to local storage.
                if (vm.hasValidSessionToken) {
                    localStorage.setItem('sessionToken', token);
                }
            });
        }

        $scope.showStartTemaproveExerciseModal = function () {
            if (!vm.hasInProgressTemaprove && vm.hasTokenExpired) {
                return false;
            }
            $scope.startTemaproveExerciseData = {};
            $('#startTemaproveExercise').modal('show');
        };

        $scope.startTemaproveExercise = function () {
            $('#startTemaproveExercise').modal('hide');

            // Wait a little bit to make the modal window close properly first
            $timeout(function() {
                $state.go('teoriprove', {
                        "type": $scope.startTemaproveExerciseData.practiceType,
                        "subject": $scope.startTemaproveExerciseData.subject
                    });
            }, 300);
        };

        $scope.showStartSkiltproveExerciseModal = function () {
            if (!vm.hasInProgressSkiltprove && vm.hasTokenExpired) {
                return false;
            }
            $scope.startSkiltproveExerciseData = {};
            $('#startSkiltproveExercise').modal('show');
        };

        $scope.startSkiltproveExercise = function () {
            $('#startSkiltproveExercise').modal('hide');

            // Wait a little bit to make the modal window close properly first
            $timeout(function() {
                $state.go('teoriprove', {
                    "type": 'SKILTPROVE_' + $scope.startSkiltproveExerciseData.numberOfExercises
                });
            }, 300);
        };

        // Check if link to start new Teoriprøve should be disabled or not.
        $scope.isTeoriproveLinkDisabled = function() {
            if (!vm.hasInProgressTeoriprove && vm.hasTokenExpired) {
                return false;
            }
        };
    }
})();
